import * as React from "react"

import GlobalLayout from "../layouts/global-layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <GlobalLayout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
  </GlobalLayout>
)

export default NotFoundPage
